import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfirmProvider, ConfirmOptions } from 'material-ui-confirm';
import { Provider as AppControllerProvider } from './controllers/AppController';

import PwaManager from './components/PwaManager';
import AppRouter from './AppRouter';

import ConditionalAuth0Provider from './components/ConditionalAuth0Provider';
import DeviceManagerComponent from './devices/DeviceManagerComponent';

import useConfigurationStore from './state/ConfigurationStore';
import logger from './utils/Logger';

import { ThemeProvider } from '@mui/material/styles';
import darkTheme from './themes/DarkTheme';
import CssBaseline from '@mui/material/CssBaseline';
import './themes/RootStyles.scss';

import { I18nextProvider } from 'react-i18next';
import i18next, { LanguageDetectorModule } from 'i18next';

// English
import common_en from './translations/en/common.json';
import account_en from './translations/en/account.json';
import prompter_en from './translations/en/prompter.json';
import appmenu_en from './translations/en/appmenu.json';
import devices_en from './translations/en/devices.json';
import tours_en from './translations/en/tours.json';

// French
import common_fr from './translations/fr/common.json';
import account_fr from './translations/fr/account.json';
import prompter_fr from './translations/fr/prompter.json';
import appmenu_fr from './translations/fr/appmenu.json';
import tours_fr from './translations/fr/tours.json';

// Spanish
import common_es from './translations/es/common.json';
import account_es from './translations/es/account.json';
import prompter_es from './translations/es/prompter.json';
import appmenu_es from './translations/es/appmenu.json';
import tours_es from './translations/es/tours.json';

// Italian
import common_it from './translations/it/common.json';
import account_it from './translations/it/account.json';
import prompter_it from './translations/it/prompter.json';
import appmenu_it from './translations/it/appmenu.json';
import tours_it from './translations/it/tours.json';

// Finnish
import common_fi from './translations/fi/common.json';
import account_fi from './translations/fi/account.json';
import prompter_fi from './translations/fi/prompter.json';
import appmenu_fi from './translations/fi/appmenu.json';
import devices_fi from './translations/fi/devices.json';
import tours_fi from './translations/fi/tours.json';

// Korean
import common_ko from './translations/ko/common.json';
import account_ko from './translations/ko/account.json';
import prompter_ko from './translations/ko/prompter.json';

// Russian
import common_ru from './translations/ru/common.json';
import account_ru from './translations/ru/account.json';
import prompter_ru from './translations/ru/prompter.json';
import appmenu_ru from './translations/ru/appmenu.json';
import devices_ru from './translations/ru/devices.json';
import tours_ru from './translations/ru/tours.json';

// Ukrainian
import common_ua from './translations/ua/common.json';
import account_ua from './translations/ua/account.json';
import prompter_ua from './translations/ua/prompter.json';
import appmenu_ua from './translations/ua/appmenu.json';
import devices_ua from './translations/ua/devices.json';
import tours_ua from './translations/ua/tours.json';

// ['en', 'fr', 'es', 'de']
const supportedLanguages = ['en', 'fr', 'es', 'it', 'fi', 'ko', 'ru', 'ua']; // (*)

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

interface IFluidprompterHostInfo {
  type: string,
  bluetooth?: string,
}

declare global {
  interface Window {
    /**
     * react-native-webview has it's own postMessage method.
     */
    ReactNativeWebView?: {
      postMessage(msg: string): void;
      injectedObjectJson: () => object;
    };

    receiveReactNativeMessage?: (msg: string) => void;

    fluidprompterHost?: IFluidprompterHostInfo;
  }

  interface Navigator {
    brave?: {
      isBrave(): Promise<boolean>;
    };
  }
}

const BrowserLanguageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    const availableLanguages = navigator.languages || ['en'];
    let selectedLanguage = availableLanguages.find((availableLanguage) => {
      return (supportedLanguages.indexOf(availableLanguage) >= 0);
    });
    if(!selectedLanguage) {
      // If we didn't find a translation yet, evaluate the list again but only evaluate the root
      // language and not dialects.
      selectedLanguage = availableLanguages.find((availableLanguage) => {
        return (supportedLanguages.indexOf(availableLanguage.split('-')[0]) >= 0);
      });
    }
    // console.log(`Detected browser language as '${selectedLanguage}'`);
    return selectedLanguage;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cacheUserLanguage: () => {}
};

function App() {
  if(!process.env.REACT_APP_AUTH0_DOMAIN) {
    /**
     * You must add the key=value for REACT_APP_AUTH0_DOMAIN to an application .env file.
     */
    throw new Error('Auth0 Domain is not configured.');
  }
  if(!process.env.REACT_APP_AUTH0_CLIENTID) {
    /**
     * You must add the key=value for REACT_APP_AUTH0_CLIENTID to an application .env file.
     */
    throw new Error('Auth0 ClientId is not configured.');
  }

  //
  // If we are running in a react-native-webview, let's make sure we have gathered any contextual
  // information from the host app.
  //
  // On certain versions of Android, the mobile app cannot inject javascript directly, but rather
  // inject a JSON object that can be retrieved via:
  //   window.ReactNativeWebView.injectedObjectJson();
  //
  // If react-native-webview was able to inject javascript `window.fluidprompterHost` will already
  // be defined and we can safely `ignore injectedObjectJson()`.
  //
  if(typeof window !== 'undefined'
     && window.ReactNativeWebView?.injectedObjectJson
     && window.fluidprompterHost === undefined
  ) {
    // We are running in ReactNative mobile app, likely on Android
    window.fluidprompterHost = window.ReactNativeWebView.injectedObjectJson() as IFluidprompterHostInfo;
  }

  i18next
    .use(BrowserLanguageDetector)
    .init({
      interpolation: { escapeValue: false },  // React already does escaping
      supportedLngs: supportedLanguages,
      fallbackLng: 'en',                      // Fallback language when a translation key is not available.
      returnNull: false,  // Don't return null for missing keys.
      resources: {
        en: {
          common: common_en,
          account: account_en,
          prompter: prompter_en,
          appmenu: appmenu_en,
          devices: devices_en,
          tours: tours_en,
        },
        fr: {
          common: common_fr,
          account: account_fr,
          prompter: prompter_fr,
          appmenu: appmenu_fr,
          tours: tours_fr,
        },
        es: {
          common: common_es,
          account: account_es,
          prompter: prompter_es,
          appmenu: appmenu_es,
          tours: tours_es,
        },
        it: {
          common: common_it,
          account: account_it,
          prompter: prompter_it,
          appmenu: appmenu_it,
          tours: tours_it,
        },
        fi: {
          common: common_fi,
          account: account_fi,
          prompter: prompter_fi,
          appmenu: appmenu_fi,
          devices: devices_fi,
          tours: tours_fi,
        },
        ko: {
          common: common_ko,
          account: account_ko,
          prompter: prompter_ko,
        },
        ru: {
          common: common_ru,
          account: account_ru,
          prompter: prompter_ru,
          appmenu: appmenu_ru,
          devices: devices_ru,
          tours: tours_ru,
        },
        ua: {
          common: common_ua,
          account: account_ua,
          prompter: prompter_ua,
          appmenu: appmenu_ua,
          devices: devices_ua,
          tours: tours_ua,
        },
      },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'], // don't convert to <1></1> if simple react elements
      },
    });

  //
  // These are the default options for confirmation dialogs.
  //
  const confirmProviderOptions = React.useMemo<ConfirmOptions>(() => {
    return {
      confirmationButtonProps: {
        variant: 'outlined',
        size: 'small',
      },
      cancellationButtonProps: {
        variant: 'contained',
        size: 'small',
        color: 'primary',
      }
    };
  }, []);

  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <AppControllerProvider>
          <ConfirmProvider defaultOptions={confirmProviderOptions}>
            <PwaManager />
            <Router>
              <ConditionalAuth0Provider>
                <AppRouter />
                <DeviceManagerComponent />
                {process.env.REACT_APP_ENV === 'development' &&
                <div style={{
                  position: 'fixed',
                  left: 0,
                  top: '50%',
                  padding: '6px 3px',
                  background: '#ff0000',
                  color: '#ffffff',
                  fontWeight: 'bold',
                  userSelect: 'none',
                  pointerEvents: 'none',
                  opacity: 0.75,
                  writingMode: 'vertical-lr',
                  textOrientation: 'sideways',
                  transform: 'translateY(-50%)',
                  whiteSpace: 'nowrap',
                }}>Development Site</div>}
              </ConditionalAuth0Provider>
            </Router>
          </ConfirmProvider>
        </AppControllerProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
